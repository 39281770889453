export const adjective = {
	singular: "provider",
	plural: "providers",
};

export const verb = {
	singular: "working",
};

export const job = {
	singular: "job",
	plural: "jobs",
};
